'use strict'

###*
 # @ngdoc service
 # @name ServiceReportVisualizeManager.factory:ServiceReportVisualizeManager

 # @description

###
angular
  .module 'mundoReporting'
  .factory 'ServiceReportVisualizeManager', [
    '$q'
    'Restangular'
    (
      $q
      Restangular
    ) ->
      Restangular.service('services/reports/visualize/basic')
  ]
